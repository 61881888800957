<template>
  <div class="modal">
    <a-modal v-model="uploadVisible" :title="$t('导入配料表')" :width="500" :footer="null">
      <a-spin :spinning="uploadLoading">
        <div>
          <a-upload-dragger
            name="file"
            :fileList="fileList"
            action=""
            :customRequest="uploadChange"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="cloud-upload" />
            </p>
            <p class="ant-upload-hint">{{ $t('点击选择或者拖拽要上传的文件') }}</p>
          </a-upload-dragger>
        </div>
      </a-spin>
    </a-modal>
    <a-modal
      v-model="propertiesVisible"
      :title="$t('配料表')"
      width="1200px"
      :dialog-style="{ top: '20px' }"
      :okText="$t('保存')"
      :confirmLoading="saveloading"
      :maskClosable="false"
      @ok="saveProperties"
    >
      <div class="modal-content flex">
        <div class="modal-content-left">
          <a-form-model>
            <a-row :gutter="16">
              <a-col v-for="(item, index) in propertiesList" :key="index" :span="12">
                <a-form-model-item :label="item.name">
                  <a-input v-model="item.value"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <table
            class="tableFa"
            cellspacing="0"
            cellpadding="1"
            width="100%"
            style="border-collapse: collapse"
            bordercolor="#333333"
          >
            <tbody style="font-size: 14px">
              <tr>
                <td colspan="3">{{ $t('营养成分表') }}</td>
              </tr>
              <tr v-for="(item, rowIndex) in nutritionList" :key="rowIndex">
                <td width="33%" v-for="(elem, colIndex) in item" :key="colIndex">
                  <span v-if="rowIndex === 0">{{ elem }}</span>
                  <a-input v-else v-model="nutritionList[rowIndex][colIndex]" />
                  <a-icon
                    type="minus-circle"
                    class="red minus-circle"
                    v-if="colIndex === 2 && rowIndex !== 0"
                    @click="delItem(rowIndex)"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="add-btn" @click="addItem">
                    <a-icon type="plus-circle" />
                    <span class="ml4">{{ $t('添加') }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-content-right">
          <div class="demo-text">{{ $t('预览') }}:</div>
          <IngredientsList
            :skuCode="skuInfo.skuCode"
            :propertiesInfo="propertiesInfo"
            :nutritionList="nutritionList"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import IngredientsList from '@component/ingredientsList'
export default {
  props: {
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { IngredientsList },
  data() {
    return {
      uploadVisible: false,
      uploadLoading: false,
      propertiesVisible: false,
      saveloading: false,
      fileList: [],
      nutritionList: [], //营养成分表
      propertiesList: [
        {
          name: this.$t('名称'),
          code: 'showName',
          value: undefined,
        },
        {
          name: this.$t('配料'),
          code: 'ingredients',
          value: undefined,
        },
        {
          name: this.$t('产品标准'),
          code: 'productStandard',
          value: undefined,
        },
        {
          name: this.$t('加工方式'),
          code: 'processingMethod',
          value: undefined,
        },
        {
          name: this.$t('贮存条件'),
          code: 'storageCondition',
          value: undefined,
        },
        {
          name: this.$t('委托商'),
          code: 'consignor',
          value: undefined,
        },
        {
          name: this.$t('委托商地址'),
          code: 'consignorAddress',
          value: undefined,
        },
        {
          name: this.$t('被委托生产商'),
          code: 'consignorProducer',
          value: undefined,
        },
        {
          name: this.$t('食品生产许可证编号'),
          code: 'foodProductionLicenseNumber',
          value: undefined,
        },
        {
          name: this.$t('生产商地址'),
          code: 'producerAddress',
          value: undefined,
        },
        {
          name: this.$t('保质期'),
          code: 'shelfLife',
          value: undefined,
        },
        {
          name: this.$t('产地'),
          code: 'origin',
          value: undefined,
        },
        {
          name: this.$t('联系电话'),
          code: 'contactNumber',
          value: undefined,
        },
        {
          name: this.$t('净含量'),
          code: 'netContent',
          value: undefined,
        },
      ],
    }
  },
  computed: {
    propertiesInfo() {
      let obj = {}
      this.propertiesList.forEach((item) => {
        obj[item.code] = item.value
      })
      return obj
    },
  },
  methods: {
    open() {
      if (this.skuInfo.properties?.showName) {
        this.nutritionList = this.skuInfo.properties.nutritionList
        this.propertiesList.forEach((item) => {
          item.value = this.skuInfo.properties[item.code]
        })
        this.propertiesVisible = true
      } else {
        this.uploadVisible = true
      }
    },
    close() {
      this.propertiesVisible = false
      this.uploadVisible = false
    },
    uploadChange(info) {
      var filetypes = ['.doc']
      var isnext = false
      var fileend = info.file.name.substring(info.file.name.lastIndexOf('.'))
      for (var i = 0; i < filetypes.length; i++) {
        if (filetypes[i] == fileend) {
          isnext = true
          break
        }
      }
      if (!isnext) {
        this.$warning(this.$t(`不接受此文件类型！`))
        info.file = undefined
        return false
      }
      const form = new FormData()
      form.append('file', info.file)
      this.uploadLoading = true
      http({
        url: api.uploadSkuNutrition,
        type: 'post',
        contentType: false,
        processData: false,
        showErrorMessage: true,
        data: form,
        success: (res) => {
          this.propertiesList.forEach((item) => {
            item.value = res.result[item.code]
          })
          this.nutritionList = res.result.nutritionList
          this.uploadLoading = false
          this.fileList = []
          this.uploadVisible = false
          this.propertiesVisible = true
        },
        fail: () => {
          this.uploadLoading = false
        },
      })
    },
    delItem(rowIndex) {
      this.nutritionList.splice(rowIndex, 1)
    },
    addItem() {
      this.nutritionList.push(['', '', ''])
    },
    saveProperties() {
      let properties = {}
      this.propertiesList.forEach((item) => {
        properties[item.code] = item.value
      })
      properties.nutritionList = this.nutritionList
      this.saveloading = true
      http({
        url: api.updateSkuProperties,
        data: {
          properties,
          skuId: this.skuInfo.skuId,
        },
        success: () => {
          this.$message.success(this.$t('保存成功'))
          this.saveloading = false
          this.propertiesVisible = false
          this.$emit('success', properties)
        },
      })
    },
  },
}
</script>
<style lang="scss">
.red {
  color: #ff0000;
}
.modal-content {
  .modal-content-left {
    width: 50%;
    .minus-circle {
      position: absolute;
      right: -25px;
      top: 10px;
      cursor: pointer;
    }
    .add-btn {
      cursor: pointer;
      padding: 6px 0;
    }
  }
  .modal-content-right {
    flex: 1;
    .demo-text {
      width: 400px;
      margin: 0 auto;
      font-size: 16px;
    }
  }
}
.tableFa td {
  border: 2px solid #333;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
</style>
