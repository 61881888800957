<template>
  <div class="ingredients-list" id="ingredients-list">
    <div style="width: 300px">
      <div class="top" style="position: relative">
        <img
          src="http://lazada-erp.oss-cn-shanghai.aliyuncs.com/1742117384554754049/96970f93cc4d41df9a8d9cbb8ccc2c88"
          alt=""
          class="logo"
          width="220"
          style="display: block; padding-top: 5px"
        />
        <img class="sku-barcode" style="width: 200px; position: absolute; top: 0; right: 0px" />
      </div>
      <div
        class="properties-info"
        id="properties-info"
        style="margin-top: 10px; font-size: 14px; position: relative; text-align: justify"
      >
        <span style="margin-left: 2px">{{ $t('名称') }}: {{ propertiesInfo.showName }}</span>
        <span style="margin-left: 2px">{{ $t('配料') }}: {{ propertiesInfo.ingredients }}</span>
        <span style="margin-left: 2px">
          {{ $t('产品标准') }}: {{ propertiesInfo.productStandard }}
        </span>
        <span style="margin-left: 2px">
          {{ $t('加工方式') }}: {{ propertiesInfo.processingMethod }}
        </span>
        <span style="margin-left: 2px">
          {{ $t('贮存条件') }}: {{ propertiesInfo.storageCondition }}
        </span>
        <span style="margin-left: 2px">{{ $t('委托商') }}: {{ propertiesInfo.consignor }}</span>
        <span style="margin-left: 2px">
          {{ $t('委托商地址') }}: {{ propertiesInfo.consignorAddress }}
        </span>
        <span style="margin-left: 2px">
          {{ $t('被委托生产商') }}: {{ propertiesInfo.consignorProducer }}
        </span>
        <span style="margin-left: 2px">
          {{ $t('食品生产许可证编号') }}: {{ propertiesInfo.foodProductionLicenseNumber }}
        </span>
        <span style="margin-left: 2px">
          {{ $t('生产商地址') }}: {{ propertiesInfo.producerAddress }}
        </span>

        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <div>{{ $t('保质期') }}: {{ propertiesInfo.shelfLife }}</div>
            <div>{{ $t('产地') }}: {{ propertiesInfo.origin }}</div>
            <div>{{ $t('联系电话') }}: {{ propertiesInfo.contactNumber }}</div>
          </div>
          <div class="netContent" style="font-size: 24px; line-height: 28px; text-align: center">
            {{ $t('净含量') }}:
            <br />
            {{ propertiesInfo.netContent }}
          </div>
        </div>
        <div :contenteditable="true">{{ $t('生产日期') }}: {{ nowDate }}</div>
      </div>
      <table
        id="nutrition-table"
        class="table-show"
        cellspacing="0"
        cellpadding="0"
        width="100%"
        style="border-collapse: collapse"
        bordercolor="#333333"
      >
        <tbody style="font-size: 12px">
          <tr>
            <td colspan="3" style="text-align: center; border: 2px solid #333">
              {{ $t('营养成分表') }}
            </td>
          </tr>
          <tr v-for="(item, rowIndex) in nutritionList" :key="rowIndex">
            <td
              width="33%"
              v-for="(elem, colIndex) in item"
              :key="colIndex"
              style="text-align: center; border: 2px solid #333"
            >
              <span>{{ elem }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode'
import { getDateStr } from '@/common/getDateStr'
export default {
  props: {
    skuCode: {
      type: String,
    },
    propertiesInfo: {
      type: Object,
      default: () => ({}),
    },
    nutritionList: {
      type: Array,
    },
    isPrint: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    skuCode: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          JsBarcode('.sku-barcode', val, {
            format: 'CODE128', //条形码的格式
            // width: 1.4, //线宽
            height: 60, //条码高度
            displayValue: false,
          })
        })
      },
    },
  },
  data() {
    return {
      nutritionListShow: [],
      nowDate: getDateStr(),
    }
  },
  methods: {},
}
</script>
<style lang="scss">
.ingredients-list {
  margin: 0 auto;
  width: 300px;
}
</style>
